import GATSBY_COMPILED_MDX from "/opt/build/repo/blog/my-first-post/index.mdx";
import * as React from 'react';
import {graphql} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
const BlogPost = ({data, children}) => {
  const image = getImage(data.mdx.frontmatter.hero_image);
  return React.createElement(Layout, {
    pageTitle: data.mdx.frontmatter.title
  }, React.createElement("p", null, data.mdx.frontmatter.date), React.createElement(GatsbyImage, {
    image: image,
    alt: data.mdx.frontmatter.hero_image_alt
  }), React.createElement("p", null, " Photo Credit:", " ", React.createElement("a", {
    href: data.mdx.frontmatter.hero_image_credit_link
  }, data.mdx.frontmatter.hero_image_credit_text)), children);
};
export const query = graphql`
  query($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        hero_image_alt
        hero_image_credit_link
        hero_image_credit_text
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
export const Head = ({data}) => React.createElement(Seo, {
  title: data.mdx.frontmatter.title
});
BlogPost
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPost, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
